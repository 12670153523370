<template>
  <div>
    <form class="form" @submit.stop.prevent="formOnsubmit()">
      <div class="card card-custom">
        <div class="card-body">
          <div
            class="pb-5"
            data-wizard-type="step-content"
            data-wizard-state="current"
          >
            <h5
              class="font-weight-bolder text-dark font-size-h6 font-size-h3-lg"
            >
              <span> {{ title }} </span>
            </h5>

            <b-form-group
              id="input-group-title"
              label="Nama Usaha:"
              label-for="input-title"
            >
              <b-form-input
                id="input-name"
                v-model="form.business_name"
                placeholder="Nama Usaha"
              ></b-form-input>
              <small class="text-danger">{{ error.business_name }}</small>
            </b-form-group>

            <b-form-group
              id="input-group-category"
              label="Komoditas:"
              label-for="input-category"
            >
              <treeselect
                v-model="form.commodity_name"
                :multiple="false"
                placeholder="Pilih Komoditas"
                :options="commodity"
              />
              <small class="text-danger">{{ error.commodity_name }}</small>
            </b-form-group>

            <!-- <b-form-group
              id="input-group-commodity"
              label="Komoditas:"
              label-for="input-commodity"
            >
              <b-form-input
                id="input-commodity"
                v-model="form.commodity_name"
                placeholder="Komoditas"
              ></b-form-input>
              <small class="text-danger">{{ error.commodity_name }}</small>
            </b-form-group> -->
            <!-- 
        <b-form-group
          id="input-group-category"
          label="Kecamatan:"
          label-for="input-category"
        >
          <treeselect
            v-model="form.district_id"
            :multiple="false"
            placeholder="Pilih Kecamatan"
            :options="district"
            @input="chooseDistrict"
          />
          <small class="text-danger">{{ error.district_id }}</small>
        </b-form-group>

        <b-form-group
          id="input-group-category"
          label="Kelurahan:"
          label-for="input-category"
          v-if="form.district_id != ''"
        >
          <treeselect
            v-model="form.village_id"
            :multiple="false"
            placeholder="Pilih Kelurahan"
            :options="village"
            @input="chooseVillage"
          />
          <small class="text-danger">{{ error.village_id }}</small>
        </b-form-group> -->

            <b-form-group
              id="input-group-category"
              label="Bidang Usaha:"
              label-for="input-category"
            >
              <treeselect
                v-model="form.village_business_category_id"
                :multiple="false"
                placeholder="Pilih Bidang Usaha"
                :options="village_category"
              />
              <small class="text-danger">{{
                error.village_business_category_id
              }}</small>
            </b-form-group>

            <b-form-group
              id="input-group-category"
              label="Status Kepemilikan Tempat Usaha:"
              label-for="input-category"
            >
              <treeselect
                v-model="form.business_place_status"
                :multiple="false"
                placeholder="Pilih Status Kepemilikan Tempat Usaha"
                :options="status"
              />
              <small class="text-danger">{{
                error.business_place_status
              }}</small>
            </b-form-group>

            <!-- <b-form-group
              id="input-group-business-place"
              label="Status Kepemilikan Tempat Usaha:"
              label-for="input-business-place"
            >
              <b-form-input
                id="input-business-place"
                v-model="form.business_place_status"
                placeholder="Status Kepemilikan Tempat Usaha"
              ></b-form-input>
              <small class="text-danger">{{
                error.business_place_status
              }}</small>
            </b-form-group> -->

            <b-form-group
              id="input-group-business-place"
              label="Produk Unggulan:"
              label-for="input-business-place"
            >
              <b-form-input
                id="input-business-place"
                v-model="form.product"
                placeholder="Produk Unggulan (Misal : topi anyam, gerabah, kepripik singkong dll)"
              ></b-form-input>
              <small class="text-danger">{{ error.product }}</small>
            </b-form-group>

            <b-form-group
              id="input-group-capital"
              label="Modal:"
              label-for="input-capital"
            >
              <b-form-input
                id="input-capital"
                v-model="form.capital"
                placeholder="Modal"
              ></b-form-input>
              <small class="text-danger">{{ error.capital }}</small>
            </b-form-group>

            <b-form-group
              id="input-group-turnover"
              label="Omset per Bulan:"
              label-for="input-turnover"
            >
              <b-form-input
                id="input-turnover"
                v-model="form.turnover"
                placeholder="Omset per Bulan"
              ></b-form-input>
              <small class="text-danger">{{ error.turnover }}</small>
            </b-form-group>

            <b-form-group id="input-group-address">
              <label for="input-address">Alamat:</label>
              <b-form-textarea
                id="input-address"
                v-model="form.address"
                placeholder="Alamat"
                rows="4"
                max-rows="8"
              ></b-form-textarea>
              <small class="text-danger">{{ error.address }}</small>
            </b-form-group>

            <b-form-group
              id="input-group-commodity"
              label="Alamat Pada Map (Akan muncul ketika anda memilih lokasi pada map):"
              label-for="input-commodity"
            >
              <b-form-input
                id="input-commodity"
                v-model="form.display_address_map"
                readonly
              ></b-form-input>
              <small class="text-danger">{{ error.display_address_map }}</small>
            </b-form-group>
          </div>
        </div>
      </div>

      <div class="card card-custom mt-3">
        <div class="card-body">
          <span class="h3 font-weight-bold d-block"
            >Tandai alamat usaha anda</span
          >
          <small class="d-block"
            >Berikan tanda pada jalan atau bangunan yang dekat dengan tempat
            usaha anda (tidak harus akurat)</small
          >

          <!-- map -->
          <div class="row mt-3">
            <div class="col-md-6">
              <div class="row">
                <div class="col-md-12">
                  <b-input-group>
                    <b-form-input
                      type="text"
                      placeholder="Masukkan keyword"
                      v-model="filter.query"
                    ></b-form-input>
                    <template #append>
                      <button
                        class="btn btn-primary"
                        @click="findAddress"
                        type="button"
                      >
                        Cari
                      </button>
                    </template>
                  </b-input-group>
                  <small
                    >Cari bangunan, tempat atau nama jalan kemudian klik pada
                    pilihan dibawah atau tandai pada map (klik di map)</small
                  >
                </div>

                <div
                  class="col-md-12 py-1"
                  v-for="(address, index) in dataAddress"
                  :key="index"
                >
                  <div class="card">
                    <div
                      class="card-body m-0 p-2"
                      @click="
                        pinAddress(
                          address.longitude,
                          address.latitude,
                          address.formattedAddress
                        )
                      "
                      style="cursor: pointer"
                    >
                      <span v-if="address.placeLabel != undefined">
                        {{
                          address.placeLabel + ", " + address.formattedAddress
                        }}
                      </span>
                      <span v-if="address.placeLabel == undefined">
                        {{ address.formattedAddress }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="openstreetmap mt-3">
                <l-map
                  style="height: 550px"
                  :zoom="zoom"
                  :center="center"
                  @click="onMapClick"
                >
                  <l-tile-layer
                    :url="url"
                    :attribution="attribution"
                  ></l-tile-layer>
                  <l-marker
                    v-for="(location, index) in locations"
                    :lat-lng="location.position"
                    v-bind:key="index"
                  >
                    <l-popup :content="location.name"></l-popup>
                  </l-marker>
                  <l-marker ref="marker" :lat-lng="[newLt, newLng]">
                    <l-popup
                      ref="popup"
                      :content="newLoc"
                      :options="{ autoClose: false, closeOnClick: false }"
                    ></l-popup>
                  </l-marker>
                </l-map>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!--begin: Wizard Actions -->
      <div class="card card-custom mt-3">
        <div class="card-body">
          <div class="d-flex justify-content-between">
            <div>
              <b-button type="submit" variant="primary">Simpan</b-button>
              <b-button
                type="button"
                class="ml-2"
                variant="default"
                @click="$router.push('/village-business/list')"
              >
                Batal
              </b-button>
            </div>
          </div>
        </div>
      </div>
      <!--end: Wizard Actions -->
    </form>
  </div>
</template>

<style type="text/css">
.leaflet-popup-close-button {
  display: none;
}
</style>
<script>
import module from "@/core/modules/CrudModule.js";
import validation from "@/core/modules/ValidationModule.js";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import { LMap, LTileLayer, LMarker, LPopup } from "vue2-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";

delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.imagePath = "";
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

export default {
  props: {
    form: Object,
    route: String,
    title: String,
  },
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LPopup,
  },
  data() {
    return {
      error: {
        business_name: "",
        regency_name: "",
        longitude: "",
        latitude: "",
        display_address_map: "",
        commodity_name: "",
        village_business_category_id: "",
        village_business_category_name: "",
        address: "",
      },
      district: {},
      village: {},

      // option
      village_category: {},
      commodity: {},
      status: {},

      // map
      filter: {
        query: "",
        addId: "",
      },
      dataAddress: {},

      zoom: 20,
      center: L.latLng(-6.30749877, 107.290332172),
      url: "http://{s}.tile.osm.org/{z}/{x}/{y}.png",
      attribution:
        'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, ' +
        '<a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, ' +
        'Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
      id: "mapbox/streets-v11",
      newLoc: "",
      newLt: 0,
      newLng: 0,
      locations: [{ name: "Karawang", position: [-6.30749877, 107.290332172] }],
    };
  },
  methods: {
    async formOnsubmit() {
      console.log(this.form, "this.form");
      // Make Request
      let response = await module.submit(this.form, this.route);
      // Check Response
      if (response.state == "error") {
        // Validation Error
        this.error = validation.setValidationError(
          this.error,
          response.error.errors
        );
      } else {
        // Success
        Swal.fire(response.success.title, response.success.message, "success");
        this.$router.push("/village-business/list");
      }
    },

    async getCategory() {
      let response = await module.setTreeSelect("village-business-category");
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.village_category = response.data;

        this.village_category.unshift({
          label: "Pilih Bidang Usaha",
          id: "",
          isDisabled: true,
        });
      }
    },

    async getCommodity() {
      let response = await module.setTreeSelect("business-status-commodity");
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.commodity = response.data;

        console.log('commodity', this.commodity)

        this.commodity.unshift({
          label: "Pilih Komoditas",
          id: "",
          isDisabled: true,
        });
      }
    },

    async getStatus() {
      let response = await module.setTreeSelect("business-status-data");
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.status = response.data;

        this.status.unshift({
          label: "Pilih Status Kepemilikan",
          id: "",
          isDisabled: true,
        });
      }
    },

    async chooseDistrict(evt) {
      let res = await fetch(
        `http://www.emsifa.com/api-wilayah-indonesia/api/district/${this.form.district_id}.json`
      ).then((response) => response.json());
      this.form.district_name = res.name;
      this.getVillage();
    },

    async chooseVillage(evt) {
      let res = await fetch(
        `http://www.emsifa.com/api-wilayah-indonesia/api/village/${this.form.village_id}.json`
      ).then((response) => response.json());
      this.form.village_name = res.name;
    },
    // get kecamatan di karawang
    async getDistrict() {
      let arr = [];
      let res = await fetch(
        `http://www.emsifa.com/api-wilayah-indonesia/api/districts/3215.json`
      ).then((response) => response.json());

      res.forEach((value) => {
        arr.push({ label: value.name, id: value.id });
      });

      // Set Option Select
      this.district = arr;
      this.district.unshift({
        label: "Pilih Kecamatan",
        id: "",
        isDisabled: true,
      });
    },

    //  get kelurahan by district
    async getVillage() {
      let arr = [];
      let res = await fetch(
        `http://www.emsifa.com/api-wilayah-indonesia/api/villages/${this.form.district_id}.json`
      ).then((response) => response.json());

      res.forEach((value) => {
        arr.push({ label: value.name, id: value.id });
      });

      // Set Option Select
      this.village = arr;
      this.village.unshift({
        label: "Pilih Kelurahan/Desa",
        id: "",
        isDisabled: true,
      });
    },

    setOtherData(lon, lat, address) {
      this.form.longitude = lon;
      this.form.latitude = lat;
      this.form.display_address_map = address;
    },

    // map
    onMapClick(e) {
      console.log(e, "log e");
      this.newLt = e.latlng.lat;
      this.newLng = e.latlng.lng;
      this.center = L.latLng(e.latlng.lat, e.latlng.lng);

      fetch(
        `https://api.radar.io/v1/geocode/reverse?coordinates=${e.latlng.lat},${e.latlng.lng}`,
        {
          headers: {
            Authorization:
              "prj_test_sk_a5ccae118f8aeab61b48f08bfecbc2f558397023",
          },
        }
      )
        .then((res) => res.json())
        .then((res) => {
          console.log(res);
          this.newLoc =
            res.addresses[0].addressLabel + ", " + res.addresses[0].city;
          this.setOtherData(this.newLng, this.newLt, this.newLoc);
        });
    },

    async findAddress() {
      fetch(
        `https://api.radar.io/v1/search/autocomplete?query=${this.filter.query}&country=id&layers=fine&near=-6.30749877,107.290332172`,
        {
          headers: {
            Authorization:
              "prj_test_sk_a5ccae118f8aeab61b48f08bfecbc2f558397023",
          },
        }
      )
        .then((res) => res.json())
        .then((res) => {
          console.log(res);
          this.dataAddress = res.addresses;
        });
    },

    async pinAddress(lon, lat, loc) {
      this.newLt = lat;
      this.newLng = lon;
      this.newLoc = loc;
      this.center = L.latLng(lat, lon);
      this.setOtherData(lon, lat, loc);
    },
  },
  mounted() {
    // this.getDistrict();
    // if (this.form.district_id != "") {
    //   this.getVillage();
    // }
    this.getCategory();
    this.getStatus();
    this.getCommodity();

    // map
    this.$nextTick(() => {
      this.$refs.marker.mapObject.openPopup();
    });
    this.newLoc = this.locations[0].name;
    this.newLt = this.locations[0].position[0];
    this.newLng = this.locations[0].position[1];

    // if (this.form.longitude != "" && this.form.latitude != "") {
    //   this.newLoc = this.form.display_address_map
    //   this.newLt = this.form.latitude;
    //   this.newLng = this.form.longitude;
    // }
  },
};
</script>
